
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import roadMapApi from "@/core/services/RoadMap";
import ExcelJS from "exceljs";
import { useI18n } from "vue-i18n";
import userApi from "@/core/services/User";
import moment from "moment";

interface Roadmap {
    label: string;
    category?: { label: string };
    status: string;
    deadline?: string;
    impact: string;
    date_created: string;
    updated: string;
    user?: {
        email: string;
        userprofile?: {
            related_companies: any;
        };
    };
}

function getLevelCompany(company, level) {
    if (company.position === level) {
        return company.name;
    } else if (company.father) {
        return getLevelCompany(company.father, level);
    } else return "";
}

export default defineComponent({
    name: "related-roadmap",
    components: {},
    data: function () {
        return {
            filters: {
                perimeter: "",
                identity: "",
            },
            filterUsers: {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            },
            action: {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            },
            chartBarOptions: {
                chart: {
                    fontFamily: "inherit",
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                    },
                },
                labels: [] as any,
            },
            seriesBar: [] as any,
            chartPieOptions: {
                chart: {
                    fontFamily: "inherit",
                    type: "donut",
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                    },
                },
                labels: [] as any,
            },
            seriesPie: [] as any,
            chartOptions: {
                chart: {
                    fontFamily: "inherit",
                    type: "radialBar",
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "65%",
                        },
                        dataLabels: {
                            showOn: "always",
                            name: {
                                show: false,
                                fontWeight: "700",
                            },
                            value: {
                                color: "#009EF7",
                                fontSize: "30px",
                                fontWeight: "700",
                                offsetY: 12,
                                show: true,
                                formatter: function (val) {
                                    return val + "%";
                                },
                            },
                        },
                        track: {
                            background: "#F5F8FA",
                            strokeWidth: "100%",
                        },
                    },
                },
                colors: ["#009EF7"],
                stroke: {
                    lineCap: "round",
                },
                labels: [],
            },
            totalUsers: 0,
            roadMapUser: 0,
            series: [] as any,
            users: [],
            roadmaps: [] as Roadmap[],
            categories: [],
            loading: true,
            noResult: false,
        };
    },
    methods: {
        downloadUsers() {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet("Extraction Roadmaps");

            // Define columns with headers and data fields
            sheet.columns = [
                { header: "Nom", key: "label", width: 20 },
                { header: "Catégorie", key: "category", width: 20 },
                { header: "Status", key: "status", width: 15 },
                { header: "Deadline", key: "deadline", width: 15 },
                { header: "Impact", key: "impact", width: 15 },
                { header: "Date de création", key: "date_created", width: 20 },
                { header: "Date de mise à jour", key: "updated", width: 20 },
                { header: "Email", key: "email", width: 25 },
                ...Array.from({ length: 10 }, (_, i) => ({
                    header: `N${i + 1}`,
                    key: `level_${i + 1}`,
                    width: 10,
                })),
            ];

            sheet.getRow(1).eachCell((cell) => {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ADD8E6" }, // Light blue background
                };
                cell.font = { bold: true };
            });
            this.roadmaps.forEach((roadmap) => {
                sheet.addRow({
                    label: roadmap.label,
                    category: roadmap.category ? roadmap.category.label : "",
                    status: roadmap.status === "INPR" ? "In Progress" : roadmap.status === "TODO" ? "To do" : "Done",
                    deadline: roadmap.deadline || "",
                    impact: roadmap.impact,
                    date_created: moment(roadmap.date_created).format("DD/MM/YYYY"),
                    updated: moment(roadmap.updated).format("DD/MM/YYYY"),
                    email: roadmap.user ? roadmap.user.email : "",
                    ...Object.fromEntries(
                        Array.from({ length: 10 }, (_, i) => [
                            `level_${i + 1}`,
                            roadmap.user && roadmap.user.userprofile ? getLevelCompany(roadmap.user.userprofile.related_companies[0], i + 1) : "",
                        ])
                    ),
                });
            });
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "extraction_roadmaps.xlsx";
                a.click();
                window.URL.revokeObjectURL(url);
            });
        },
        fetchUsers() {
            this.loading = true;
            userApi.getUsers(this.filterUsers).then((response) => {
                this.users = response.data["users"];
                this.roadMapUser = response.data["user_with_roadmaps"];
                this.totalUsers = this.users.length;
                console.log(this.roadMapUser);
                this.series = [Math.round((this.roadMapUser * 100) / this.totalUsers)];
                this.loading = false;
            });
        },
        getRelatedRoadMaps() {
            this.loading = true;
            this.noResult = false;
            roadMapApi.getAllRoadMaps(this.filters).then((response) => {
                let roadmaps = response.data.roadmaps;
                this.roadmaps = response.data.roadmaps;
                let categories = response.data.categories;
                categories.forEach((category) => {
                    this.chartPieOptions.labels.push(category.label);
                    this.seriesPie.push(category.roadmaps.length);
                });
                let inprogress_roadmaps = roadmaps.filter((roadmap) => {
                    if (roadmap.status === "INPR") return roadmap;
                }).length;
                let todo_roadmaps = roadmaps.filter((roadmap) => {
                    if (roadmap.status === "TODO") return roadmap;
                }).length;
                let done_roadmaps = roadmaps.filter((roadmap) => {
                    if (roadmap.status === "DONE") return roadmap;
                }).length;
                this.chartBarOptions.labels = ["A FAIRE", "EN COURS", "TERMINÉE"];
                this.seriesBar = [
                    {
                        data: [
                            {
                                x: "A FAIRE",
                                y: todo_roadmaps,
                            },
                            {
                                x: "EN COURS",
                                y: inprogress_roadmaps,
                            },
                            {
                                x: "TERMINÉE",
                                y: done_roadmaps,
                            },
                        ],
                    },
                ];
                console.log(this.seriesBar[0].data);
                this.loading = false;
            });
        },
    },
    setup() {
        const { t } = useI18n();

        function createDebounce() {
            let timeout: ReturnType<typeof setTimeout>;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        return { t, debounce: createDebounce() };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.title"), [
                {
                    link: "/roadmap/",
                    label: this.t("pages.roadmap.title"),
                },
            ]);
        },
    },
    created() {
        this.getRelatedRoadMaps();
        this.fetchUsers();
    },
});
